<template>
	<div class="wrapper">
		<figure class="bg-video-wrapper">
            <img :src="imageUrl" />
		</figure>
		<base-link class="logo-link" title="Strona główna Oknar Kępno Sp. Z o.o." target="_self" url="/">
			<base-svg name="logo_white" class="logo-icon"/>
		</base-link>
		<div class="error-wrapper">
			<p class="error">404</p>
			<base-font color="white" size="md"  class="error-info">Wybrana strona nie istnieje</base-font>
			<base-link class="back-link" url="/" title="Powrót na stronę główną" target="_self">
				Powrót na stronę główną
			</base-link>
		</div>
	</div>
</template>

<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import { getFileUrl } from 'utils/getFileUrl'

export default {
	mixins: [
		pageMixin
	],
	computed: {
		imageUrl () {
			const imagePath = 'notfound/404.jpg'
			return getFileUrl(this.$app, imagePath)
		}
	}
}
</script>

<style lang="scss" scoped>
.wrapper {
	padding: 0;
	margin: 0;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	display: grid;
	place-items: center;
	grid-template-rows: 200px 1fr 200px;
	@include media-breakpoint-landscape() {
		grid-template-rows: 75px 1fr 50px;
	}
	@include media-breakpoint-up(lg) {
		grid-template-rows: 200px 1fr 200px;
		grid-template-columns: repeat(3, 1fr);
	}
	.logo-link {
		grid-row: 1;
		background: none;
		animation: fadeToBottom 1s ease-in alternate;
		@include media-breakpoint-up(lg) {
			grid-column: 2;
			place-self: center;
		}
	}
	.logo-icon {
		height: auto;
		width: 20rem;
		@include media-breakpoint-landscape() {
			width: 15rem;
		}

		path {
			fill: $white;
		}
	}

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba($black, 0.5);
		z-index: -1;
	}
	.bg-video-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		z-index: -2;
		background: $black;

		.bg-video {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	.error-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-row: 2;
		animation: fadeToTop 1s ease-in alternate;
		@include media-breakpoint-up(lg) {
			grid-column: 2;
		}

		.error {
			color: $white;
			font-size: 15rem;
			line-height: 15rem;
			margin-block: 2rem;
			font-weight: bold;
			text-shadow: 4px 4px $secondary;
			@include media-breakpoint-up(lg) {
				font-size: 25rem;
				line-height: 25rem;
			}
		}
		::v-deep .error-info {
			margin-top: 0rem;
			margin-bottom: 3rem;
			font-weight: 500;
			text-transform: uppercase;
			@include media-breakpoint-landscape() {
				margin-bottom: 1rem;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 1rem;
				margin-bottom: 4rem;
			}

		}
	}
	& .back-link {
		margin: 0 auto 0;
		display: block;
		padding: 1.5rem 3rem;
		border-radius: 5px;
		display: inline-block;
		background-color: $white;
		text-transform: uppercase;
		text-decoration: none;
		color: $primary;
		transition: .3s ease;
		&:hover {
			background-color: rgba($white, 0.7);
		}
	}
}
@keyframes fadeToBottom {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fadeToTop {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
